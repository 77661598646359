import { useCallback } from 'react';
import { AxiosRequestConfig } from 'axios';
import {
  GetSecuritySettingsResponse,
  NoContentResult,
  UpdateSecuritySettingsRequest,
  GetRetentionPeriodResponse,
  UpdateSoftDeletionRetentionPeriodRequest,
  UpdateRetentionPeriodRequest,
  RestoreDataRequest,
} from '@uniqkey-backend-organization-web/api-client';
import { useApiClients } from '../../contexts/APIClientsContext';
import { dataExtractor } from '../../helpers/apiClients';

const useOrganizationSecuritySettingsAPI = () => {
  const { organizationSecuritySettingsAPIClient } = useApiClients();

  const getOrganizationSecuritySettings = useCallback(async (
    options?: AxiosRequestConfig,
  ): Promise<GetSecuritySettingsResponse> => organizationSecuritySettingsAPIClient
    .apiV1SecuritySettingsGet(options)
    .then(dataExtractor<GetSecuritySettingsResponse>()), [organizationSecuritySettingsAPIClient]);

  const updateOrganizationSecuritySettings = useCallback(async (
    securitySettings?: UpdateSecuritySettingsRequest,
    options?: AxiosRequestConfig,
  ): Promise<NoContentResult> => organizationSecuritySettingsAPIClient
    .apiV1SecuritySettingsPut(securitySettings, options)
    .then(dataExtractor<NoContentResult>()), [organizationSecuritySettingsAPIClient]);

  const getOrganizationRetentionPeriod = useCallback(async (
    options?: AxiosRequestConfig,
  ): Promise<GetRetentionPeriodResponse> => organizationSecuritySettingsAPIClient
    .apiV1SecuritySettingsGetRetentionPeriodGet(options)
    .then(dataExtractor<GetRetentionPeriodResponse>()), [organizationSecuritySettingsAPIClient]);

  const updateSoftDeletionRetentionPeriod = useCallback(async (
    updateSoftDeletionRetentionPeriodRequest?: UpdateSoftDeletionRetentionPeriodRequest,
    options?: AxiosRequestConfig,
  ): Promise<NoContentResult> => organizationSecuritySettingsAPIClient
    .apiV1SecuritySettingsUpdateSoftDeletionRetentionPeriodPost(
      updateSoftDeletionRetentionPeriodRequest,
      options,
    )
    .then(dataExtractor<NoContentResult>()), [organizationSecuritySettingsAPIClient]);

  const updateGeneralRetentionPeriod = useCallback(async (
    updateRetentionPeriodRequest?: UpdateRetentionPeriodRequest,
    options?: AxiosRequestConfig,
  ): Promise<NoContentResult> => organizationSecuritySettingsAPIClient
    .apiV1SecuritySettingsUpdateRetentionPeriodPost(
      updateRetentionPeriodRequest,
      options,
    )
    .then(dataExtractor<NoContentResult>()), [organizationSecuritySettingsAPIClient]);

  const restoreData = useCallback(async (
    restoreDataRequest?: RestoreDataRequest,
    options?: AxiosRequestConfig,
  ): Promise<NoContentResult> => organizationSecuritySettingsAPIClient
    .apiV1SecuritySettingsRestoreDataPost(
      restoreDataRequest,
      options,
    )
    .then(dataExtractor<NoContentResult>()), [organizationSecuritySettingsAPIClient]);

  return {
    getOrganizationSecuritySettings,
    updateOrganizationSecuritySettings,
    getOrganizationRetentionPeriod,
    updateSoftDeletionRetentionPeriod,
    updateGeneralRetentionPeriod,
    restoreData,
  };
};

export default useOrganizationSecuritySettingsAPI;
