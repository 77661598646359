import Papa from 'papaparse';
import { IReadEmployeesResult, IUserFromCSV } from '../interfaces';
import CsvHeadersEnum from '../../../../../enums/CsvHeadersEnum';

interface IReadProps {
  files: File[],
}

// eslint-disable-next-line import/prefer-default-export
export const read = async (props: IReadProps): Promise<IReadEmployeesResult[]> => {
  const { files } = props;

  const promises = Array.from(files).map((file) => new Promise<IReadEmployeesResult>((res, rej) => {
    if (file.type !== 'text/csv') {
      rej(new Error('inviteEmployeesModal.invalidFileFormat'));
    }
    const reader = new FileReader();
    if (!reader) {
      rej(new Error('common.somethingWentWrong'));
    }
    reader.readAsText(file, 'UTF-8');
    reader.onloadend = (evt) => {
      const result = evt.target?.result as string;
      if (!result) {
        rej(new Error('inviteEmployeesModal.fileIsEmpty'));
        return;
      }
      Papa.parse<IUserFromCSV>(result, {
        header: true,
        skipEmptyLines: true,
        transformHeader: ((header: string) => header.toLowerCase().trim()),
        complete(readResult) {
          const [firstHeader, secondHeader] = readResult.meta.fields ?? [];
          if (firstHeader !== CsvHeadersEnum.Email) {
            rej(new Error('inviteEmployeesModal.invalidHeaders'));
          }
          if (secondHeader && secondHeader !== CsvHeadersEnum.Name) {
            rej(new Error('inviteEmployeesModal.invalidHeaders'));
          }
          res({ users: readResult.data, fileName: file.name });
        },
      });
    };
    reader.onerror = () => {
      rej(new Error('inviteEmployeesModal.readFileError'));
    };
  }));
  return Promise.all(promises);
};
