import {
  WidgetContainer,
  Grid,
  Typography,
  Divider,
  S6,
  useTranslations,
  Button,
  INumericTextFieldProps,
  ReactHookFormAutocomplete,
  NumericTextField,
  TFunction,
  noLeadingZeroValidator,
  minMaxValueValidator,
  useSnackbar,
  resetReactQuery,
  usePubSub,
} from '@uniqkey-frontend/shared-app';
import { useForm } from 'react-hook-form';
import {
  useCallback, memo, SyntheticEvent, useState,
} from 'react';
import {
  RestoreDataType,
  GetRetentionPeriodResponse,
} from '@uniqkey-backend-organization-web/api-client';
import { useQueryClient } from 'react-query';
import { logException } from '../../../../../../services/sentryService';
import useOrganizationSecuritySettingsAPI
  from '../../../../../../hooks/useOrganizationSecuritySettingsAPI';
import { REACT_QUERY_EMPLOYEES_KEY } from '../../../../../../hooks/tables/useEmployeesTable';
import {
  REACT_QUERY_CREDIT_CARDS_KEY,
} from '../../../../../../hooks/tables/useEmployeeGroupCreditCardsTable';
import {
  REACT_QUERY_SECURE_NOTES_KEY,
} from '../../../../../../hooks/tables/useEmployeeGroupSecureNotesTable';
import { REACT_QUERY_LOGINS_KEY } from '../../../../../../hooks/tables/useEmployeeGroupLoginsTable';
import { REACT_QUERY_AUDIT_LOGS_KEY } from '../../../../../../hooks/tables/useAuditLogsTable';
import PubSubEventEnum from '../../../../../../enums/PubSubEventEnum';

interface IRestoreDataFormValue {
  daysToRestore: string;
  restoreDataTypes: IRestoreDataTypesOption[];
}

interface IRestoreDataTypesOption {
  label: string;
  value: RestoreDataType;
}

interface IRestoreDataWidgetProps {
  retentionPeriodInDays: GetRetentionPeriodResponse['retentionPeriodInDays'];
  isRetentionPeriodDataLoading: boolean;
}

const getRestoreDataTypesOptions = (t: TFunction) => [
  { label: t('restoreDataTypes.allData'), value: RestoreDataType.AllData },
  { label: t('restoreDataTypes.Employee'), value: RestoreDataType.Employee },
  { label: t('restoreDataTypes.PaymentCards'), value: RestoreDataType.PaymentCards },
  { label: t('restoreDataTypes.SecureNotes'), value: RestoreDataType.SecureNotes },
  { label: t('restoreDataTypes.Logins'), value: RestoreDataType.Logins },
  { label: t('restoreDataTypes.AuditLogs'), value: RestoreDataType.AuditLogs },
];

const RESTORE_DATA_TYPE_TO_REACT_QUERY_KEYS_MAP = {
  [RestoreDataType.Employee]: REACT_QUERY_EMPLOYEES_KEY,
  [RestoreDataType.PaymentCards]: REACT_QUERY_CREDIT_CARDS_KEY,
  [RestoreDataType.SecureNotes]: REACT_QUERY_SECURE_NOTES_KEY,
  [RestoreDataType.Logins]: REACT_QUERY_LOGINS_KEY,
  [RestoreDataType.AuditLogs]: REACT_QUERY_AUDIT_LOGS_KEY,
};

const RestoreDataWidget = (props: IRestoreDataWidgetProps) => {
  const {
    retentionPeriodInDays,
    isRetentionPeriodDataLoading,
  } = props;
  const { t } = useTranslations();
  const { showError, showSuccess } = useSnackbar();
  const { restoreData } = useOrganizationSecuritySettingsAPI();
  const queryClient = useQueryClient();

  const [isRestoreDataLoading, setIsRestoreDataLoading] = useState(false);

  const {
    register,
    control,
    setValue,
    watch,
    handleSubmit,
    reset,
    trigger,
    formState: { errors },
  } = useForm<IRestoreDataFormValue>({
    mode: 'all',
    defaultValues: {
      daysToRestore: '',
      restoreDataTypes: [],
    },
  });

  const [
    watchDaysToRestore,
    watchRestoreDataTypes,
  ] = watch([
    'daysToRestore',
    'restoreDataTypes',
  ]);

  const handleDaysToRestoreChange = useCallback<
    NonNullable<INumericTextFieldProps['onChange']>
  >((event) => {
    setValue('daysToRestore', event.target.value);
  }, [setValue]);

  const handleRestoreDataTypesChange = useCallback((
    event: SyntheticEvent,
    value: IRestoreDataTypesOption[],
  ) => {
    if (!value.length) return;
    const lastAddedRestoreDataType = value[value.length - 1];
    const actualRestoreDataTypes = value.filter((
      restoreDataType,
    ) => restoreDataType.value !== RestoreDataType.AllData);
    if (lastAddedRestoreDataType.value === RestoreDataType.AllData) {
      setValue('restoreDataTypes', [lastAddedRestoreDataType]);
      return;
    }
    setValue('restoreDataTypes', actualRestoreDataTypes);
  }, [setValue]);

  const handleRestoreData = useCallback(async (formValue: IRestoreDataFormValue): Promise<void> => {
    try {
      setIsRestoreDataLoading(true);
      const {
        daysToRestore,
        restoreDataTypes,
      } = formValue;

      const parsedDaysToRestore = parseInt(daysToRestore, 10);
      const parsedRestoreDataTypes = restoreDataTypes.map(
        (restoreDataType) => restoreDataType.value,
      );

      await restoreData({
        daysToRestore: parsedDaysToRestore,
        restoreDataTypes: parsedRestoreDataTypes,
      });
      showSuccess({
        text: t('settingsPage.retentionPeriodTab.restoreDataWidget.success.message'),
      });
      reset();
      const [firstElement] = parsedRestoreDataTypes;
      if (firstElement === RestoreDataType.AllData) {
        Object.values(RESTORE_DATA_TYPE_TO_REACT_QUERY_KEYS_MAP).forEach((key) => {
          resetReactQuery(queryClient, key);
        });
        return;
      }
      parsedRestoreDataTypes.forEach((type) => {
        resetReactQuery(queryClient, RESTORE_DATA_TYPE_TO_REACT_QUERY_KEYS_MAP[
          type as keyof typeof RESTORE_DATA_TYPE_TO_REACT_QUERY_KEYS_MAP
        ]);
      });
    } catch (e) {
      showError({ text: t('common.somethingWentWrong') });
      logException(e, { message: 'RestoreDataWidget/handleRestoreData exception' });
    } finally {
      setIsRestoreDataLoading(false);
    }
  }, [queryClient, reset, restoreData, showError, showSuccess, t]);

  usePubSub(PubSubEventEnum.RETENTION_PERIOD_CHANGED, () => {
    trigger('daysToRestore');
  });

  const disabled = !!errors.daysToRestore || !watchDaysToRestore || !watchRestoreDataTypes.length;

  if (isRetentionPeriodDataLoading) {
    return (
      <WidgetContainer
        container
        withShadow
        minHeight={280}
        p={3}
        isLoading={isRetentionPeriodDataLoading}
      />
    );
  }

  return (
    <WidgetContainer container withShadow p={3}>
      <Grid container flexDirection="column">
        <form onSubmit={handleSubmit(handleRestoreData)} autoComplete="off">
          <Grid item mb={0.5}>
            <Typography variant="body3" color={S6}>
              {t('settingsPage.retentionPeriodTab.restoreDataWidget.title')}
            </Typography>
          </Grid>
          <Divider />
          <Grid item container flexDirection="column" mt={2.5} rowGap={2}>
            <Grid container gap={1}>
              {/* Same padding as on TextField to center text */}
              <Grid item pt="12px" width={135}>
                <Typography>
                  {t('settingsPage.retentionPeriodTab.restoreDataWidget.restoreForTime.title')}
                </Typography>
              </Grid>
              <Grid item xs>
                <NumericTextField
                  allowLeadingZeros
                  name="daysToRestore"
                  decimalSeparator=""
                  allowNegative={false}
                  fullWidth
                  autoComplete="off"
                  error={!!errors.daysToRestore}
                  helperText={errors.daysToRestore?.message}
                  placeholder={t('settingsPage.retentionPeriodTab.textField.placeholder')}
                  value={watchDaysToRestore}
                  onChange={handleDaysToRestoreChange}
                  inputProps={{
                    ...register('daysToRestore', {
                      validate: {
                        noLeadingZero: (value) => noLeadingZeroValidator(value)
                          || t('validation.noLeadingZero'),
                        minMaxValue: (value) => minMaxValueValidator({
                          value,
                          minValue: 1,
                          maxValue: retentionPeriodInDays,
                        }) || t('validation.restoreDataShouldNotExceedRetentionPeriod'),
                      },
                    }),
                  }}
                />
              </Grid>
            </Grid>
            <Grid container gap={1}>
              {/* Same padding as on TextField to center text */}
              <Grid item pt={1.5} width={135}>
                <Typography>
                  {t('settingsPage.retentionPeriodTab.restoreDataWidget.dataForRestore.title')}
                </Typography>
              </Grid>
              <Grid item xs>
                <ReactHookFormAutocomplete
                  name="restoreDataTypes"
                  placeholder={t(
                    'settingsPage.retentionPeriodTab.restoreDataWidget.dataForRestore.placeholder',
                  )}
                  options={getRestoreDataTypesOptions(t)}
                  control={control}
                  multiple
                  fullWidth
                  onChange={handleRestoreDataTypesChange}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container item mt={3} justifyContent="flex-end">
            <Button
              variant="outlined"
              type="submit"
              isLoading={isRestoreDataLoading}
              disabled={disabled}
            >
              {t('common.apply')}
            </Button>
          </Grid>
        </form>
      </Grid>
    </WidgetContainer>
  );
};

export default memo(RestoreDataWidget);
