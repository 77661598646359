import { WidgetContainer } from '@uniqkey-frontend/shared-app';
import {
  GetRetentionPeriodResponse,
} from '@uniqkey-backend-organization-web/api-client';
import { memo } from 'react';
import SoftDeletionPeriodWidgetContainer from '../SoftDeletionPeriodWidgetContainer';

export interface ISoftDeletionPeriodWidgetProps {
  data: Pick<GetRetentionPeriodResponse, 'softDeletionPeriodInDaysForArchivedEmployees'
    | 'softDeletionPeriodInDaysForUnmanagedVaults' | 'softDeletionPeriodInDaysForAuditLogs'
  >
  isLoading: boolean;
  isError: boolean;
}

const SoftDeletionPeriodWidget = (props: ISoftDeletionPeriodWidgetProps) => {
  const {
    data, isLoading, isError,
  } = props;

  if (isError) {
    return null;
  }

  if (isLoading) {
    return (
      <WidgetContainer container withShadow minHeight={420} p={3} isLoading={isLoading} />
    );
  }

  return (
    <SoftDeletionPeriodWidgetContainer data={data} />
  );
};

export default memo(SoftDeletionPeriodWidget);
